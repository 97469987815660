<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-checkbox
              label="Nouveau Produit"
              v-model="newproduct"
              dense
              @change="newproduct_click"
            ></v-checkbox>
            <v-spacer> </v-spacer>
            <v-radio-group
              v-if="!newproduct"
              v-model="service"
              row
              mandatory
              dense
            >
              <v-radio label="Produits" :value="0"></v-radio>
              <v-radio label="Service" :value="1"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8" v-if="newproduct">
                  <v-text-field
                    autocomplete="off"
                    ref="label"
                    dense
                    v-model="editedItem.label"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="!newproduct && allproduct">
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                  v-if="!newproduct && dem_type == 2 && !allproduct"
                >
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    @change="produit_change"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ (item.code ? item.code + "-" : "") + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.code ? item.code + '-' : '') + item.label
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="!newproduct && spec && dem_type == 2"
                >
                  <v-checkbox
                    label="Tous"
                    v-model="allproduct"
                    dense
                    @change="cs++"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="
                      editedItem.unit_name ? editedItem.unit_name : 'Quantité'
                    "
                    :rules="[
                      (v) =>
                        !!v ||
                        (editedItem.unit_name
                          ? editedItem.unit_name
                          : 'Quantité') + ' obligatoire',
                      (v) => !v || v > 0 || 'Valeur incorrecte',
                    ]"
                    @keydown.enter="save"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-select
                    ref="unite"
                    :items="units_list"
                    v-model="editedItem.fk_unit"
                    item-text="name"
                    item-value="id"
                    label="Unité de Mesure"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="description"
                    dense
                    v-model="editedItem.description"
                    label="Description"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_DEMANDE_DETAIL from "../graphql/Demande/CREATE_DEMANDE_DETAIL.gql";
import UPDATE_DEMANDE_DETAIL from "../graphql/Demande/UPDATE_DEMANDE_DETAIL.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";
import { spec } from "print/data.js";

export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "demdetailform",

  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    dem_type: Number,
    tier_products: Array,
    units: Array,
  },

  data: () => ({
    service: 0,
    duedate: null,
    newproduct: false,
    allproduct: true,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    template: 0,
    attributs: [],
    units_list: [],
    unfilter: false,
    pageInfo: null,
    label: null,
    produit_id: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
  }),

  computed: {
    variableadd() {
      let w = {
        Kind: this.dem_type == 1 ? 2 : 3,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: 2,
      };
      return w;
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    spec() {
      return spec;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    products_list() {
      let list = [];
      let l = this.dem_type == 2 ? this.tier_products : [];

      l = l.filter(
        (elm) =>
          !this.items.map((elm2) => elm2.label).includes(elm.label) ||
          elm.label == this.editedItem.label
      );
      if (l)
        list = l.filter(
          (elm) => elm.service == this.service || elm.service == null
        );
      return list;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    this.allproduct = !this.spec || this.dem_type == 1;
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id > 0) {
        this.produit_id = this.editedItem.produit_id;
        this.cs++;
        if (!this.editedItem.produit_id) {
          this.newproduct = true;
          this.newproduct_click();
        }
        this.produit_change(this.editedItem.produit_id);
      }
    }
  },

  methods: {
    newproduct_click() {
      if (this.newproduct) {
        this.editedItem.produit_id = null;
        this.units_list = this.units;
      } else {
        this.editedItem.label = null;
        this.editedItem.pu = null;
        this.editedItem.qte = null;
        this.editedItem.fk_unit = null;
        this.units_list = [];
        this.$refs.form.resetValidation();
      }
    },
    get_units() {
      let k = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_product
      );
      if (k >= 0) {
        let c = this.units[k].category_id;
        this.units_list = this.units.filter((elm) => elm.category_id == c);
      }
      k = this.units_list.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_buy
      );
      if (k == -1) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_buy
        );
        if (i >= 0)
          this.units_list.push({
            id: this.units[i].id,
            name: this.units[i].name,
          });
      }
    },
    produit_change(item, l) {
      let i;
      let list = this.products_list;
      if (l) list = l;
      this.produit_id = item;
      if (this.produit_id) {
        i = list.findIndex((elm) => elm.id == this.produit_id);

        if (i >= 0) {
          this.editedItem.produit_id = list[i].id;
          let produit = list[i];
          this.editedItem.fk_unit_buy = produit.fk_unit_buy;
          this.editedItem.fk_unit_product = produit.fk_unit;

          //unite de mesure
          if (produit.fk_unit && this.editedItem.id < 0)
            this.editedItem.fk_unit = produit.fk_unit;

          this.get_units();

          this.editedItem.unit_name = produit.unit_name;

          this.$refs.qte.focus();
        }
      } else this.units_list = this.units.filter((elm) => elm.uom_type == "m");
    },

    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        let v;
        let ok = true;

        if (ok) {
          if (this.editedItem.id > 0) {
            v = {
              demandedetail: {
                id: this.editedItem.id,
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                label: this.editedItem.label,
                fk_unit: this.editedItem.fk_unit,
                description: this.editedItem.description,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            };

            this.maj(UPDATE_DEMANDE_DETAIL, v);
          } else {
            v = {
              demandedetail: {
                qte: parseFloat(this.editedItem.qte),
                description: this.editedItem.description,
                produit_id: this.editedItem.produit_id,
                label: this.editedItem.label,
                fk_unit: this.editedItem.fk_unit,
                dem_id: this.item.dem_id,
                comment: this.editedItem.comment,
                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            v.demandedetail.dem_id = this.item.dem_id;
            this.maj(CREATE_DEMANDE_DETAIL, v);
          }
        }
      }
    },
  },
};
</script>
